// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import BlockHelpers from "../../utilities/src/BlockHelpers";
import { parseFooter } from "../../utilities/src/helpers/utils";
import { Platform } from "../../utilities/src/models/Platform";
import { Navigation } from "../../utilities/src/models/Navigation";
import { getStorageData } from "../../../framework/src/Utilities";
import { AppMixpanel as mixpanel } from "../../../components/src/MixPanel";
import StorageProvider from "../../../framework/src/StorageProvider";

export const configJSON = require("./config");

export interface Footer {
	data: {
		attributes: {
			footer: {
				id: number,
				copy_right_text: string,
				phone_number: string,
				country_code: string,
				social_media: {
					facebook: {
						selected: boolean,
						url: string
					},
					twitter: {
						selected: boolean,
						url: string
					},
					instagram: {
						selected: boolean,
						url: string
					},
					youtube: {
						selected: boolean,
						url: string
					}
				},
				download_apps: {
					android: {
						selected: boolean,
						url: string
					},
					ios: {
						selected: boolean,
						url: string
					}
				}
			}
		}
	}
}

export interface Props {
  navigation: Navigation;
  identifier: string;
}
interface footerAttributes {
  copyRight: string;
  facebookUrl: string;
  twitterUrl: string;
  youtubeUrl: string;
  linkedinUrl: string;
  instagramUrl: string;
  appstoreUrl: string;
  playstoreUrl: string;
  designId: number;
  isSocialMedia: boolean;
  isAppLink: boolean;
  isContact: boolean;
  phoneNumber: number;
  addressFull: string;
  footerEmail:string,
  whatsAppNumber:number,
  whatsAppMessage:string;
  isWhatsappIntegration:boolean;
}

interface footersData {
  id: string;
  type: string;
  attributes?: footerAttributes;
}

interface DesignAttributes {
  footer?: {
      data?: footersData;
  };
}


interface S {
  phoneNumber: string;
  footerEmail:string;
  whatsAppNumber:string;
  whatsAppMessage:string;
  isWhatsappIntegration:boolean;
  socialMedias: Record<string, Platform>;
  applications: Record<string, Platform>;
  copyright: string;
  staticPages: StaticPage[];
  brandFooterFacebookUrl:string;
  brandFooterInstagramUrl:string;
  brandFooterXurl:string;
  brandFooterYoutubeurl:string;
  brandFooteAppStoreUrl:string;
  brandFootePlayStoreUrl:string;
  copyRight:string;
  brandFootertogle:boolean;
  brandFooterContact:boolean;
  brandFooterApplink:boolean;
  isLoading:boolean
  loading:boolean;
  buildCardId: string;
  footerData:footersData;
}

interface SS {
  identifier: string;
}

export type StaticPage = {
  id: string;
  type: string;
  attributes: {
    id: number;
    title: string;
    description: {
      content: string;
    };
    active: boolean;
    position: number;
    page_type: {
      id: number;
      name: string;
    };
    page_slug: string;
    created_at: string;
    updated_at: string;
  };
};

export default class AppfooterController extends BlockComponent<Props, S, SS> {
  getBrandsApiCallId: string;
  getStaticPagesMessageId: string;
  getDesignDataCallId: string;
  getStoreDetailsCallId:string;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      phoneNumber: "",
      footerEmail:"",
      whatsAppNumber:"",
      whatsAppMessage:"",
      isWhatsappIntegration:false,
      socialMedias: {},
      applications: {},
      copyright: "",
      staticPages: [],
      brandFooterFacebookUrl:"",
      brandFooterInstagramUrl:"",
      brandFooterXurl:"",
      brandFooterYoutubeurl:"",
      brandFooteAppStoreUrl:"",
      brandFootePlayStoreUrl:"",
      copyRight:"",
      brandFootertogle:false,
      brandFooterContact:false,
      brandFooterApplink:false,
      isLoading:false,
      loading:false,
      buildCardId: "",
      footerData:{
        id: "",
        type: ""
      }
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getBrands();
    this.getStaticPages();
    this.getDesignData();
    this.getStoreDetails();
   }

  receive = async (from: string, message: Message) => {
    runEngine.debugLog("Message Recived", message);
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getBrandsApiCallId != null &&
      this.getBrandsApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors && responseJson.data) {
        this.setState({loading:false},()=>{
          this.handleGetBrandsResponse(responseJson);
        })
      } else {
        this.setState({
          socialMedias: {},
          applications: {},
          copyright: "",
          loading:false
        });

        const errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        mixpanel.track(`${this.state.buildCardId}_webcustomer_error_occurred_in_brands`,
        {
            error: errorReponse, 
            buildCardID: this.state.buildCardId
        });

        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    if(this.getStaticPagesMessageId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))){
        const responseJSON = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if(responseJSON?.data){
          this.setState({
            staticPages: responseJSON.data.filter((staticPage: StaticPage) => staticPage.attributes.active,
           ), 
          })
        }else{
          const errorReponse = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
          );

          mixpanel.track(`${this.state.buildCardId}_webcustomer_error_occurred_in_static_page`,
          {
              error: errorReponse, 
              buildCardID: this.state.buildCardId
          });
  
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }

    if (this.getDesignDataCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJSON = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJSON?.data) {
        const buildCardId = await StorageProvider.get("buildcardID");
        const storeDetails = JSON.parse(await StorageProvider.get("storeDetails"));

        this.setState({
          buildCardId: JSON.parse(buildCardId),
          footerData: responseJSON.data.attributes.footer.data,
          brandFooterFacebookUrl: responseJSON.data.attributes.footer.data.attributes.facebook_url,
          brandFooterInstagramUrl: responseJSON.data.attributes.footer.data.attributes.instagram_url,
          brandFooterXurl: responseJSON.data.attributes.footer.data.attributes.x_url,
          brandFooterYoutubeurl: responseJSON.data.attributes.footer.data.attributes.youtube_url,
          brandFootertogle: responseJSON.data.attributes.footer.data.attributes.is_social_media,
          brandFooterContact: responseJSON.data.attributes.footer.data.attributes.is_contact,
          brandFooterApplink: responseJSON.data.attributes.footer.data.attributes.is_app_link,
          brandFooteAppStoreUrl: responseJSON.data.attributes.footer.data.attributes.appstore_url,
          brandFootePlayStoreUrl: responseJSON.data.attributes.footer.data.attributes.playstore_url,
          copyRight: responseJSON.data.attributes.footer.data.attributes.copyright,
          phoneNumber: storeDetails?.phone_number || ""
        })
      }
    }

    if (this.getStoreDetailsCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJSON = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJSON?.data) {
        this.setState({footerEmail : responseJSON.data.attributes.customer_support_email ,isWhatsappIntegration:responseJSON.data.attributes.is_whatsapp_integration,
          whatsAppNumber:responseJSON.data.attributes.whatsapp_number,whatsAppMessage:responseJSON.data.attributes.whatsapp_message
         })
      }
    }
  };

  handleGetBrandsResponse = (
    responseJson: Footer
  ) => {
    const response = responseJson;
    
    const {
      socialMedias,
      applications,
      copyright,
    } = parseFooter(response);

    this.setState({
      socialMedias,
      applications,
      copyright,
    });
  };
  
  getBrands = () => {
    this.setState({loading:true})
    const header = {
      "Content-Type": configJSON.contentTypeApplicationJson
    };
    this.getBrandsApiCallId = BlockHelpers.callApi({
      method: configJSON.getMethod,
      endPoint: configJSON.getBrandsEndPoint,
      header
    });
  };

  getStaticPages = () => {
    const header = {
      "Content-Type": configJSON.contentTypeApplicationJson
    };

    this.getStaticPagesMessageId = BlockHelpers.callApi({
      method: configJSON.getMethod,
      endPoint: configJSON.getStaticPagesEndPoint,
      header
    });
  }
  getStoreDetails = () => {
    const header = {
      "Content-Type": configJSON.contentTypeApplicationJson
    };

    this.getStoreDetailsCallId = BlockHelpers.callApi({
      method: configJSON.getMethod,
      endPoint: "bx_block_store_details/public/store_profile",
      header
    });
};

  getDesignData = () => {
    const header = {
      "Content-Type": configJSON.contentTypeApplicationJson
    };

    this.getDesignDataCallId = BlockHelpers.callApi({
      method: configJSON.getMethod,
      endPoint: configJSON.createContentWebsiteBuilderEndPoint,
      header
    });
  };
}
// Customizable Area End
