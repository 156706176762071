Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.contentTypeApplicationJson = 'application/json'

exports.getBrandsEndPoint = "bx_block_catalogue/brands/home_brand_footer";
exports.getStaticPagesEndPoint = 'bx_block_static_pages/static_pages'
exports.createContentWebsiteBuilderEndPoint = "bx_block_website_builder/designs/get_design"; 
exports.getSeoEndPoint = "bx_block_seo_setting/seo_settings/open_api_seo?slug="
exports.getMethod = 'GET';
exports.helpCenterText = "Help Center";
exports.contactUsText = "Contact us";
exports.sendAMssageText = "Send a message";
exports.downloadAppText = "Download App";
exports.followUsOnText = "Follow Us On";

// Customizable Area End
