// Customizable Area Start
import React, { ChangeEvent, RefObject } from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import BlockHelpers from "../../utilities/src/BlockHelpers";
import { parseHeader, parseLocation } from "../../utilities/src/helpers/utils";
import { ServiceImage } from "../../utilities/src/models/ServiceImage";
import { Navigation } from "../../utilities/src/models/Navigation";
import { saveStoreDetails } from "../../utilities/src/components/Currency.web";
import storageProvider from "../../../framework/src/StorageProvider.web";
import { AppMixpanel as mixpanel } from "../../../components/src/MixPanel";
import { setStorageData } from "../../../framework/src/Utilities";
import { URL } from "url";
export const configJSON = require("./config");

export interface Props {
  navigation: Navigation;
  identifier: string;
}

export interface ServiceList {
  id: number,
  title: string,
  is_service: boolean
}

export interface AppHeaderResponse {
  data: AppHeaderData
}

export interface bookingData {
  id: string,
  type: string,
  attributes: {
      order_number: string,
      status: string,
      total: number,
      service: {
          id: number,
          title: string,
          price: number,
          discount_price: string,
          discount_option: boolean,
          duration: number
      },
      service_images: {
          id: number,
          url: string,
          thumbnail_url: string,
          small_url: string,
          webp_url: string
      },
      payment_mode: string,
      currency: {
          id: number,
          name: string,
          symbol: string
      },
      updated_by_id: string,
      catalogue_price: number,
      timezone: string,
      time_zone_short: string,
      order_date: string
      appointment_date: string
      customer: {
          id: number,
          full_phone_number: string
          email: string
          full_name: string
          created_at: string
          updated_at: string
          appointment_id: number,
          comment: string
      },
      country: {
          id: number,
          name: string,
          code: string,
          phone_code: string,
      },
      service_provider: string,
      billing_address: string,
      cancellation_policy: {
          is_cancellation_policy: boolean,
          no_of_days_cancel: number,
          cancel_text: string,
      },
      reschedule_policy: {
          is_reschedule_policy: boolean,
          no_of_days_reschedule: number,
          reschedule_text: string,
      },
      refund_policy: {
          is_refund_policy: boolean,
          refund_text: string,
      }
  }
}

export interface serializer {
  data: [bookingData]
}

export interface manageBookingResponse {
  serializer: serializer,
  cancel_status: boolean,
  reschedule_status: boolean
}

export interface AppHeaderData {
  attributes: {
    header: {
      data: {
        id: string,
        type: string,
        attributes: {
          show_business_name: boolean,
          is_mobile_menu: boolean,
          logo_url: string,
          favicon_url: string,
          logo_filename: string,
          favicon_filename: string,
          metadata: string,
          logo_colour: string,
          logo_font: string;
        }
      }
    }
  }
}

export interface SearchlistResponseInf {
  search_data: Array<ServiceList>
}

interface S {
  logo: string | null;
  storeName: string;
  searchQuery: string;
  searchRef: string;
  searchListSuggestions: Array<ServiceList>;
  openClose: boolean;
  isServiceProvider: boolean;
  renameItem1: string;
  renameItem2: string;
  renameItem3: string;
  show_business_name: boolean;
  isModalOpen: boolean;
  email: string;
  emailError: string;
  bookingId: string;
  bookingIdError: string;
  loading:boolean;
  buildCardID: string;
  color: string;
  font: string;
}

interface SS {
  identifier: string;
}

export default class AppheaderController extends BlockComponent<Props, S, SS> {
  getBrandsApiCallId: string = "";
  getStoreDetailsCallId: string = "";
  searchServiceListAPICallId: string = "";
  getManageBookingAPICallId: string = "";
  getLocationApiCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SearchQueryMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationPropsMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationMessage),
    ];

    this.state = {
      openClose: false,
      logo: null,
      storeName: "",
      searchQuery: "",
      searchRef: "",
      searchListSuggestions: [],
      isServiceProvider: false,
      renameItem1: "",
      renameItem2: "",
      renameItem3: "",
      show_business_name: false,
      isModalOpen: false,
      email: '',
      emailError: '',
      bookingId: '',
      bookingIdError: '',
      loading:false,
      buildCardID: "",
      color: "",
      font: ""
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    const buildCardID = await storageProvider.get("buildCardID");
    this.setState({
      buildCardID: buildCardID
    });
    this.getBrands();
    this.getStoreDetails();
    this.getServiceProviderDetail();
    this.getLocation();
    
    window.addEventListener('popstate', this.handleBackButton);
  }

  async componentWillUnmount() {
    window.removeEventListener('popstate', this.handleBackButton);
  }

  receive = async (from: string, message: Message) => {
    runEngine.debugLog("Message Recived", message);
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const navigationData = message.getData(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getBrandsApiCallId != null &&
      this.getBrandsApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {


      if (responseJson && !responseJson.errors && responseJson.data) {
        storageProvider.set("designDetails", JSON.stringify(responseJson.data));
        this.handleGetBrandsResponse(responseJson);
      } else {
        this.setState({
          logo: null,
          storeName: "",
        });

        const errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        mixpanel.track(`${this.state.buildCardID}_webcustomer_error_occurred_in_brands`,
        {
            error: errorReponse, 
            buildCardID: this.state.buildCardID
        });

        this.parseApiCatchErrorResponse(errorReponse);
      }
    } else if (getName(MessageEnum.SearchQueryMessage) === message.id) {
      const searchQuery = message.getData("SearchQueryMessage").trim();
      this.setState({ searchQuery });
    } else if (this.getStoreDetailsCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      if(responseJson.data){
      this.getServiceProviderCount(responseJson?.data?.attributes?.service_provider_available) 
      window.location.href !== window.location.origin + "/" &&
     ( document.title = responseJson?.data?.attributes?.name || "SSServiceInternal");   
      setStorageData(
        "store_name",
        responseJson.data?.attributes?.name
      );
      this.setState({
        buildCardID: responseJson?.data?.attributes?.buildcard_name,
        storeName: responseJson?.data?.attributes?.name
      })
      storageProvider.set("buildCardID", responseJson?.data?.attributes?.buildcard_name);
      saveStoreDetails(responseJson?.data?.attributes);
      }else{
        mixpanel.track(`${this.state.buildCardID}${configJSON.errorInStorageDetails}`,
        {
            error: responseJson.errors[0], 
            buildCardID: this.state.buildCardID
        });
      }
    } else if (this.searchServiceListAPICallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.error) {
        this.handlesearchServiceListResponse(responseJson);
      } else {
        const errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
          );

          mixpanel.track(`${this.state.buildCardID}_webcustomer_error_occurred_in_search_srvice_list`,
          {
              error: errorReponse, 
              buildCardID: this.state.buildCardID
          });

        this.parseApiCatchErrorResponse(errorReponse);
      }
    } else if (this.getManageBookingAPICallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors) {
        this.handleManageBookingResponse(responseJson);
      } else if (responseJson.errors) {
        this.handleManageBookingResponse(responseJson);
        mixpanel.track(`${this.state.buildCardID}_webcustomer_error_occurred_in_manage_booking`,
        {
            error: responseJson.errors, 
            buildCardID: this.state.buildCardID
        });
        const errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getLocationApiCallId != null &&
      this.getLocationApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors && responseJson.data) {
        this.handleGetLocationResponse(responseJson);
      } else if(responseJson.errors) {
        const errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorReponse);
      }
    } else if(navigationData && navigationData?.openBookingModal)
    {
      this.toogleModal();
    }

  };

  handleGetLocationResponse = (
    responseJson: Record<string, string | number | Object | Object[]>
  ) => {
    const response = responseJson?.data;

    storageProvider.set("commonSettings", JSON.stringify(parseLocation(response)))
  };

  getLocation = () => {
    const header = {
      "Content-Type": configJSON.contentTypeApplicationJson,
    };

    this.getLocationApiCallId = BlockHelpers.callApi({
      method: configJSON.getMethod,
      endPoint: configJSON.getLocationEndPoint,
      header,
    });
  };

  handleGetBrandsResponse = (responseJson: AppHeaderResponse) => {
    const response = responseJson.data;
    const { logo, renameItem1, renameItem2, renameItem3, show_business_name, color, font  } = parseHeader(response, this.state.storeName);

    this.setState({
      logo,
      renameItem1,
      renameItem2,
      renameItem3,
      show_business_name,
      color,
      font
    });
  };

  getBrands = () => {
    const header = {
      "Content-Type": configJSON.contentTypeApplicationJson
    };

    this.getBrandsApiCallId = BlockHelpers.callApi({
      method: configJSON.getMethod,
      endPoint: configJSON.getBrandsEndPoint,
      header
    });
  };

  confirmSearch = (searchQuery: string) => {
    this.setState({ searchQuery });
    this.props.navigation.navigate("Advancesearch", {
      searchQuery
    });
  };

  getStoreDetails = () => {
    const header = {
      "Content-Type": configJSON.contentTypeApplicationJson
    };

    this.getStoreDetailsCallId = BlockHelpers.callApi({
      method: configJSON.getMethod,
      endPoint: configJSON.getStoreDetails,
      header
    });
  }

  searchServiceList = (queryRef: RefObject<HTMLInputElement>) => {
    this.setState({
      openClose:true,
      loading:true
    })
    if (queryRef.current) {
      this.setState({ searchRef: queryRef.current?.value })
    }

    const header = {
      "Content-Type": configJSON.contentTypeApplicationJson,
    };

    const query = (queryRef.current && queryRef.current.value)
    this.searchServiceListAPICallId = BlockHelpers.callApi({
      method: configJSON.getMethod,
      endPoint: configJSON.searchServiceListAPIEndPoint + "?query=" + query,
      header,
    });
  };

  handlesearchServiceListResponse = (responseData: SearchlistResponseInf) => {
   
    this.setState({ searchListSuggestions: responseData.search_data,loading:false})
    
  }

  handleNavigation = ( route: string ) => {
    const navigationMessage = new Message(getName(MessageEnum.NavigationMessage));
    navigationMessage.addData(getName(MessageEnum.NavigationTargetMessage), route);
    navigationMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    runEngine.sendMessage(navigationMessage.messageId, navigationMessage);
  }

  handleManageBookingResponse = (responseData: manageBookingResponse) => {
    storageProvider.set("bookingData", JSON.stringify(responseData))
    this.toogleModal();
    this.setState({email: '', bookingId: ''})
    this.handleNavigation("OrderStatus")
  }

  searchElementTitleFunction = (searchQuery: string, isService: boolean) => {
    this.setState({
      openClose:false,
    })
    if (isService) {
      this.confirmSearchService(searchQuery);
    } else {
      this.confirmSearchProvider(searchQuery)
    }
  };

  confirmSearchService = (searchQuery: string) => {
    this.props.navigation.navigate("ServicesSearch", {
      searchQuery
    });
    this.setState({ searchQuery });
  };

  confirmSearchProvider = (searchQuery: string) => {
    this.props.navigation.navigate("StaffSearch", {
      searchQuery
    });
    this.setState({ searchQuery });
  };

  closeSearch = () => {
    this.setState({
      openClose:false,
      loading:false
    })
  }

  getServiceProviderCount = (isAvailable: boolean) => {
    this.setState({ isServiceProvider: isAvailable })
    window.localStorage.setItem("serviceProvider", JSON.stringify(isAvailable));
  }

  getServiceProviderDetail = () => {
    const serviceProviderDetails = localStorage.getItem("serviceProvider");
    if (serviceProviderDetails && serviceProviderDetails !== "undefined") {
      this.setState({isServiceProvider: JSON.parse(serviceProviderDetails)});
    }
  }

  toogleModal = () => {
    mixpanel.track(`${this.state.buildCardID}_${configJSON.navigationManageBooking}`, { buildCardID: this.state.buildCardID });
    this.setState({email: '', bookingId: '', emailError: '', bookingIdError: ''})
    this.setState({ isModalOpen: !this.state.isModalOpen,loading:false },() => {
      if(this.state.isModalOpen)
      {
        setTimeout(() => {
          document.body.style.cssText = 'overflow: hidden !important';
        }, 0);
      }
    })
  }

  isValidEmail = (value : string) => {
    if(value === '') {
      this.setState({emailError: configJSON.emailRequired})
      return false;
    } else if(!(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)))
    {
      this.setState({emailError: configJSON.invalidEmail})
      return false;
    } else {
      this.setState({emailError: ''})
      return true;
    }
  }

  isValidBookingId = (value : string) => {
    if(value === '')
    {
      this.setState({bookingIdError: configJSON.bookingIdRequired})
      return false;
    }
    else {
      this.setState({bookingIdError: ''})
      return true;
    }
  }

  isValidButtonSubmit = () => {
    const { email, bookingId } = this.state;
    let isValidEmail = false, isValidBookingId = false;
    isValidEmail = this.isValidEmail(email);
    isValidBookingId = this.isValidBookingId(bookingId)
    return isValidEmail && isValidBookingId
  }
  

  handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.isValidEmail(event.target.value)
    this.setState({email: event.target.value})
  }
  handleBookingIdChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.isValidBookingId(event.target.value)
    this.setState({bookingId: event.target.value})
  }

  searchBookingDetails = () => {
    const header = {
      "Content-Type": configJSON.contentTypeApplicationJson
    };

    this.getManageBookingAPICallId = BlockHelpers.callApi({
      method: configJSON.getMethod,
      endPoint: `${configJSON.getManageBookingEndPoint}?email=${this.state.email}&order_id=${this.state.bookingId}`,
      header
    });
  }

  handleSubmit = () => {
    if(this.isValidButtonSubmit())
    {
      mixpanel.track(`${this.state.buildCardID}_${configJSON.proceedManageBookingForm}`, { buildCardID: this.state.buildCardID });
      this.searchBookingDetails()
    }
  }

  handleBackButton = (event:{ type: string }) => {
    if (event.type === 'popstate') {
      this.setState({email: '', bookingId: '', emailError: '', bookingIdError: ''})
      this.setState({ isModalOpen: false })
      setTimeout(() => {
        document.body.style.cssText = 'overflow: auto';
      }, 0);
    }
  }
}
// Customizable Area End
